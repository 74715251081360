<template>
    <div class="flex input_box">
        <div class="title_box">
            <p class="title"><span></span>限时发布</p>
        </div>
        <el-date-picker @focus="get_time" :picker-options="pickerOptions" value-format="timestamp" v-model="time"
            type="datetime" placeholder="选择日期时间">
        </el-date-picker>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now();
                },
            },
            time: '',

        }
    },
    methods: {
        get_time() {
            if (!this.time) {
                this.time = new Date()
            }
            this.$emit('get_timer', new Date(this.time).getTime())
        },
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/Fill';

::v-deep .el-button--text {
    display: none !important;
}
</style>